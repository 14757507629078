import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from "../../../../environments/environment";
import {UtilsService} from "../utils.service";

@Injectable()
export class ApiContactService {
  api_name = "call_contact"
  constructor(
    private httpClient: HttpClient,
    private utils:UtilsService
  ) {
  }
  get(filter={}):Observable<any> {
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/filter`,filter, this.utils.getAuthHeader())
  }
  getItem(id):Observable<any> {
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/${id}`, this.utils.getAuthHeader())
  }
  delete(ids): Observable<any>{
    return this.httpClient.delete<any>(`${environment.api_url}/api/${this.api_name}/${ids}`, this.utils.getAuthHeader())
  }
  add(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}`,obj, this.utils.getAuthHeader())
  }
  edit(id,obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/${id}`,obj, this.utils.getAuthHeader())
  }
  editInfo(id,field,value): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/update/${id}`, {field,value}, this.utils.getAuthHeader())
  }
  import(obj): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/import`,obj, this.utils.getAuthHeader())
  }
  // export(list_ids): Observable<any>{
  //   return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/export/${list_ids}`, this.utils.getAuthHeader())
  // }

  export(list_ids,page=1,limit=100): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/export/${list_ids}?limit=${limit}&page=${page}`, this.utils.getAuthHeader())
  }
  getHistory(id): Observable<any>{
    return this.httpClient.get<any>(`${environment.api_url}/api/${this.api_name}/history/${id}`, this.utils.getAuthHeader())
  }
  sendEmail(id,obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/send-email/${id}`,obj, this.utils.getAuthHeader())
  }
  sendSms(id,obj): Observable<any>{
    return this.httpClient.put<any>(`${environment.api_url}/api/${this.api_name}/send-sms/${id}`,obj, this.utils.getAuthHeader())
  }
  searchContact(text): Observable<any>{
    return this.httpClient.post<any>(`${environment.api_url}/api/${this.api_name}/search`,{text}, this.utils.getAuthHeader())
  }

}



